<template>
    <div class="base-video">
        <video
            class="base-video__video"
            autoplay="true"
            muted="true"
            loop="true"
            playsinline="true"
        >
            <source
                type="video/webm"
                :src="webm"
            >

            <source
                type="video/mp4"
                :src="mp4"
            >
        </video>
        <!-- <transition name="placeholder-fade">
            <BaseImage
                v-if="!loaded"
                :fade-in-view="false"
                loading="eager"
                class="base-video__poster"
                :image="poster?.[0]"
                sizes="(min-width: 1200px) 1200px, (min-width: 800px) 800px, 400px"
            />
        </transition> -->
    </div>
</template>

<script setup>
defineProps({
    mp4: {
        type: String,
        default: ''
    },
    webm: {
        type: String,
        default: ''
    },
    poster: {
        type: Array,
        default: () => []
    }
});

const loaded = ref(false);

onMounted(() => {
    loaded.value = true;
});
</script>

<style lang="less">
.base-video {
    position: relative;
    width: 100%;
    height: 100%;
}

.base-video__video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
}

.base-video__poster,
.base-video .base-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
}

.placeholder-fade-enter-active,
.placeholder-fade-leave-active {
    transition: opacity .5s ease-in-out;
    transition-delay: .5s;
}

.placeholder-fade-enter-from,
.placeholder-fade-leave-to {
    opacity: 0;
}
</style>